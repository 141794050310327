import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
//import Footer from './Footer';

import '../assets/sass/main.scss';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
    if(typeof document !== 'undefined') {
      const { css = '' } = props;
      if(css !== '' ) {
        document.getElementsByTagName("html")[0].style.backgroundImage = '';
        document.getElementsByTagName("html")[0].className = css;
      }
    }
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
   
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children } = this.props;
    const { isPreloaded } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}

        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'Era' },
                {
                  name: 'keywords',
                  content: 'arquitectura, interiorismo, diseño, medellin',
                },
              ]}
            >
            <html lang="en"  />
            </Helmet>
            <div className={isPreloaded ? 'is-preload' : ''}
                style={{
                transition: 'transform .5s ease-out',
                zIndex: -1,
                display: 'block',
                position: 'relative'
              }}>
            {children}
            </div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  logo: PropTypes.string,
  css: PropTypes.string

};

export default React.memo(Layout);
